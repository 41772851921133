import React from "react";
import Footer from "../components/Footer";
import LoginForm from "../components/LoginForm";
import Navbar from "../components/Navbar";

const Login = () => {
  return (
    <div className="lg:mx-40 md:mx-20 mx-5">
      <Navbar />
      <LoginForm />
      <Footer />
    </div>
  );
};

export default Login;
