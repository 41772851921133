import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const ErrorMessage = (msg) => {
  toast(msg, {
    position: "bottom-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });
};

export const serverHost = "https://insurancerepair.netlify.app";
// export const serverHost = "http://localhost:8888";
//dashboard

export const dashboardFormClasses = {
  rows: "flex sm:justify-between sm:items-center sm:flex-row flex-col gap-2",
  dropdownList:
    "dropdown-toggle px-6 py-2.5 bg-slate-600 text-white font-medium text-xs leading-tight uppercase shadow-xl hover:bg-slate-800 hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg active:text-white transition duration-150 ease-in-out flex items-center whitespace-nowrap justify-between sm:w-96 w-full cursor-pointer truncate",
  ul_tag_classes:
    "dropdown-menu w-full  min-w-max absolute hidden text-base z-50 float-left py-2 list-none text-left shadow-lg mt-1 hidden m-0 bg-clip-padding border-none bg-gradient-to-b from-slate-400 to-slate-500   [&[data-te-dropdown-show]]:block",
  li_tag_classes:
    "dropdown-item text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-white hover:bg-slate-400 cursor-pointer",
  svgIcon: (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="caret-down"
      className="w-2 ml-2"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
    >
      <path
        fill="currentColor"
        d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
      ></path>
    </svg>
  ),
  allFormsHeadings:
    "h-20 border-b text-xl sm:text-3xl font-bold uppercase tracking-[3px] w-full flex items-center",
};
export const isNotEmptyObj = (obj) => {
  return Object.values(obj).every((val) => val);
};

// dashboard from 2
export const suggestedWorkshops = [
  {
    workshopName: "fair deals",
    image:
      "https://images.unsplash.com/photo-1532522750741-628fde798c73?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHw%3D&w=1000&q=80",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, repellendus?",
    price: "65",
  },
  {
    workshopName: "minor deals",
    image: "https://wallpaperaccess.com/full/302495.jpg",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, repellendus?",
    price: "56",
  },
  {
    workshopName: "vipe deals",
    image:
      "https://images.unsplash.com/photo-1532522750741-628fde798c73?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHw%3D&w=1000&q=80",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, repellendus?",
    price: "54",
  },
  {
    workshopName: "chain deals",
    image:
      "https://png.pngtree.com/thumb_back/fh260/background/20210913/pngtree-cloud-computing-technology-background-image_877094.jpg",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero, repellendus?",
    price: "90",
  },
];
