import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div className="h-28 border-b border-gray-300 flex items-center justify-center lg:justify-between lg:flex-row flex-col gap-3">
        <div className="uppercase text-2xl sm:text-4xl text-[#45afe3] font-extrabold">
          Priceparent.de
        </div>
        <div className="flex items-center justify-center gap-3 sm:gap-10 md:gap-20 lg:gap-5 text-gray-500 sm:text-lg">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? "bg-slate-200 px-3 py-1 rounded font-bold"
                : "px-3 py-1 rounded-md"
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/login"
            className={({ isActive }) =>
              isActive
                ? "bg-slate-200 px-3 py-1 rounded font-bold"
                : "px-3 py-1 rounded-md"
            }
          >
            Anmelden
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default Navbar;
