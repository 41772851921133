import { ToastContainer } from "react-toastify";
// import { useStateContext } from "../contexts/ContextProvider";
// import Loader from "./Loader";
import * as React from "react";

import Loader from "./Loader";
import { NavLink } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";

const Account = () => {
  const { dynamicUserData } = useStateContext();
  const {
    companyName,
    emailAddress,
    ort,
    streetAddress,
    zipCode,
    phoneNumber,
    houseNumber,
  } = dynamicUserData;
  return (
    <div>
      <Loader />
      <div>
        <br />
        <br />
        <NavLink
          to="/myjobs"
          className="px-5 bg-black text-white w-20 text-center py-2 rounded"
        >
          zurück
        </NavLink>
      </div>
      <div className="mt-10">
        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden">
                <div className="">
                  <img
                    src={
                      dynamicUserData.imageUrl
                        ? dynamicUserData.imageUrl
                        : "https://cdn-icons-png.flaticon.com/512/61/61205.png"
                    }
                    alt=""
                    className="w-40 h-40 rounded-full m-auto my-10"
                  />
                </div>
                <table className="min-w-full text-center text-sm font-light">
                  <thead className="border bg-neutral-50 font-medium "></thead>
                  <tbody>
                    <tr className="border dark:border-neutral-500">
                      <td className="font-bold whitespace-nowrap  px-6 py-4 ">
                        Unternehmen
                      </td>
                      <td className="whitespace-nowrap  px-6 py-4">
                        {companyName}
                      </td>
                    </tr>
                    <tr className="border dark:border-neutral-500">
                      <td className="font-bold whitespace-nowrap  px-6 py-4 ">
                        Email Address
                      </td>
                      <td className="whitespace-nowrap  px-6 py-4 ">
                        {emailAddress}
                      </td>
                    </tr>
                    <tr className="border dark:border-neutral-500">
                      <td className="font-bold whitespace-nowrap  px-6 py-4 ">
                        Ort
                      </td>
                      <td className="whitespace-nowrap  px-6 py-4 ">{ort}</td>
                    </tr>
                    <tr className="border dark:border-neutral-500">
                      <td className="font-bold whitespace-nowrap  px-6 py-4 ">
                        Straße
                      </td>
                      <td className="whitespace-nowrap  px-6 py-4 ">
                        {streetAddress}
                      </td>
                    </tr>
                    <tr className="border dark:border-neutral-500">
                      <td className="font-bold whitespace-nowrap  px-6 py-4 ">
                        Hausnummer
                      </td>
                      <td className="whitespace-nowrap  px-6 py-4 ">
                        {houseNumber}
                      </td>
                    </tr>
                    <tr className="border dark:border-neutral-500">
                      <td className="font-bold whitespace-nowrap  px-6 py-4 ">
                        PostleitzahI
                      </td>
                      <td className="whitespace-nowrap  px-6 py-4 ">
                        {zipCode}
                      </td>
                    </tr>
                    <tr className="border dark:border-neutral-500">
                      <td className="font-bold whitespace-nowrap  px-6 py-4 ">
                        Rufnummer
                      </td>
                      <td className="whitespace-nowrap  px-6 py-4 ">
                        {phoneNumber}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Account;
