import React from "react";
import Account from "../components/Account";
import UserNavbar from "../components/UserNavbar";

const MyAccount = () => {
  return (
    <div className="lg:mx-40 md:mx-20 mx-5">
      <UserNavbar />
      <Account />
    </div>
  );
};

export default MyAccount;
