import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { serverHost } from "./config";

const ProtectedRoutes = (props) => {
  const { Component } = props;
  const navigate = useNavigate();
  useEffect(() => {
    let authToken = localStorage.getItem("auth-token");
    fetch(`${serverHost}/api/verification`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": authToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success && Component.name === "Login") {
          navigate("/myjobs");
        }
        if (!res.success) {
          navigate("/login");
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [navigate, Component.name]);

  return (
    <>
      <Component />
    </>
  );
};

export default ProtectedRoutes;
