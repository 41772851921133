import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useStateContext } from "../contexts/ContextProvider";

const Loader = () => {
  const { loader, setLoader } = useStateContext();
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loader}
      onClick={() => {
        setLoader(false);
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
