import { useState, useMemo } from "react";
import { ToastContainer } from "react-toastify";
import { serverHost } from "../config";
// import { useStateContext } from "../contexts/ContextProvider";
// import Loader from "./Loader";
import * as React from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import Loader from "./Loader";
import MaterialReactTable from "material-react-table";
import Button from "@mui/material/Button";
import { Tab, initTE } from "tw-elements";
import { ErrorMessage } from "../config";
import UploadIcon from "@mui/icons-material/Upload";
import { NavLink } from "react-router-dom";
// dialogue
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// dialogue end

const Jobs = () => {
  const [jobList, setJobList] = useState([]);
  const [requestedJobList, setRequestedJobList] = useState([]);

  const { setLoader, dynamicUserId } = useStateContext();

  const calDoublePlanPriceTemp = (plan) => {
    let price = 0;
    plan.forEach((element) => {
      price += Number(element.price);
    });
    return String(price);
  };

  const fetchData = () => {
    const shopId = dynamicUserId;
    const data = { shopId };
    fetch(`${serverHost}/api/admin/shop/claims`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoader(false);
        console.log({ res });
        setJobList(res.jobs);
      })
      .catch((err) => {
        console.log({ err });
      });

    fetch(`${serverHost}/api/admin/shop/claims/requested`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoader(false);
        console.log({ res });
        setRequestedJobList(res.requestedJobs);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  useEffect(() => {
    initTE({ Tab });
  }, []);

  useEffect(() => {
    setLoader(true);

    dynamicUserId && fetchData();
    // eslint-disable-next-line
  }, [setJobList, setLoader, dynamicUserId]);

  const [open, setOpen] = React.useState(false);
  const [openJobdialogue, setOpenJobdialogue] = React.useState(false);
  const [singleJob, setSingleJob] = useState({});
  const handleClickOpen = (row) => {
    console.log({ row });
    setOpen(true);

    setSingleJob({
      ...singleJob,
      customerName: row.firstName + " " + row.surName,
      customerEmailAddress: row.emailAddress,
      customerAddress: row.streetNumber + " , " + row.postalCodeCity,
      customerPhone: row.phoneNumber,
      jobStatus: row.status,
      customer_imei: row.imei,
      insuranceCompanyName: row.insuranceCompany[0].companyName,
      IncEmailAddress: row.insuranceCompany[0].emailAddress,
      incPhone: row.insuranceCompany[0].phoneNumber,
      address:
        row.insuranceCompany[0].ort +
        " ,  " +
        row.insuranceCompany[0].houseNumber +
        " ,  " +
        row.insuranceCompany[0].streetAddress +
        " ,  " +
        row.insuranceCompany[0].zipCode,
      manufacturer:
        row.claims.length > 0 ? row.claims[0].manufacturer[0].name : "Gelöscht",
      model:
        row.claims.length > 0
          ? row.claims[0].manufacturer[0].models[0].modalName
          : "Gelöscht",
      modelPrice:
        row.claims.length > 0
          ? row.claims[0].manufacturer[0].models[0].modalPrice
          : "Gelöscht",
      damageParts:
        row.claims.length > 0
          ? row.claims.length > 1
            ? row.claims.map((el) => el.damagePart[0].damagePart + ", ")
            : row.claims[0].damagePart[0].damagePart
          : "Gelöscht",
      planPrice:
        row.claims.length > 0
          ? row.claims.length > 1
            ? calDoublePlanPriceTemp(row.claims)
            : row.claims[0].price
          : "Gelöscht",
    });
  };

  const [imageFile, setImageFile] = useState("");
  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [claimId, setClaimId] = useState("");
  const [uploadBtn, setUploadBtn] = useState(false);
  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const handleImageUpload = () => {
    setLoader(true);
    const formData = new FormData();
    formData.append("file", imageFile);
    formData.append("upload_preset", "webhp__presents__124");

    axios
      .post("https://api.cloudinary.com/v1_1/dlse7qi1k/image/upload", formData)
      .then((response) => {
        const imagePublicUrl = response.data.secure_url;
        setImageUrl(imagePublicUrl);
        setUploadBtn(true);
        setLoader(false);
        setOpenJobdialogue(false);
        if (response) {
          handleUploadBill(imagePublicUrl);
        }
      })
      .catch((error) => {
        setUploadBtn(false);
        console.error(error);
      });
  };
  const handleUploadBill = (imageUrl) => {
    const data = { id: claimId, imageUrl };
    if (imageUrl !== "") {
      fetch(`${serverHost}/api/ppvs/claims/addBill`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((res) => {
          console.log({ res });
          if (res.success) {
            ErrorMessage(res.message);
          }
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  };
  const handleClickOpenJob = (id) => {
    setOpenJobdialogue(true);
    setImageFile("");
    setImageName("");
    setImageUrl("");
    setUploadBtn(false);
    setIsImageUploaded(false);
    const data = { id };
    fetch(`${serverHost}/api/ppws/isImageUploaded`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });
        if (res.success) {
          setIsImageUploaded(true);
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  const handleUploadBillDetail = (row) => {
    setClaimId(row._id);
  };

  const handleUpdateStatus = (info, status, requestedStatus) => {
    setLoader(true);
    let data = {};
    !requestedStatus
      ? (data = {
          status,
          manufacturer: info.claims[0].manufacturer[0].name,
          modal: info.claims[0].manufacturer[0].models[0].modalName,
          damages: allDamagesString(info.claims),
          price: allClaimsPriceAddString(info.claims) + " €",
          ppvs_email: info.insuranceCompany[0].emailAddress,
          ownerEmail: info.emailAddress,
          imei: info.imei,
        })
      : (data = {
          status,
          manufacturer: info.phoneManufacture,
          modal: info.phoneModal,
          damages: info.damages.map((el) => el + ", "),
          price: info.price + " €",
          ppvs_email: info.insuranceCompany[0].emailAddress,
          ownerEmail: info.emailAddress,
          imei: info.imei,
        });
    fetch(
      requestedStatus
        ? `${serverHost}/api/ppws/claims/requested/updateStatus/${info._id}`
        : `${serverHost}/api/ppws/claims/updateStatus/${info._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log({ oyee: "print " });
        fetchData();
        setLoader(false);
        console.log({ res });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const allDamagesString = (claims) => {
    const damages = [];
    claims.forEach((element) => {
      damages.push(element.damagePart[0].damagePart);
    });
    return damages.join(", ");
  };

  const allClaimsPriceAddString = (claims) => {
    let price = 0;
    claims.forEach((element) => {
      price += Number(element.price);
    });
    return String(price);
  };

  const handleUpdatePrice = (info, price) => {
    setLoader(true);
    const data = {
      price,
      manufacturer: info.phoneManufacture,
      modal: info.phoneModal,
      damages: info.damages.map((el) => el + ", "),
      ppvs_email: info.insuranceCompany[0].emailAddress,
      ownerEmail: info.emailAddress,
      imei: info.imei,
    };
    fetch(`${serverHost}/api/ppws/claims/requested/updatePrice/${info._id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        fetchData();
        setLoader(false);
        console.log({ res });
      })
      .catch((err) => {
        console.log({ err });
      });
  };
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => row._id,
        header: "id",
      },
      {
        accessorFn: (row) => row.status,
        header: "hideStatus",
      },
      {
        accessorFn: (row) => row.insuranceCompany[0].companyName,
        header: "Name der Versicherung",
        size: 300,
      },
      {
        accessorFn: (row) =>
          row.insuranceCompany[0].houseNumber +
          " , " +
          row.insuranceCompany[0].streetAddress +
          " , " +
          row.insuranceCompany[0].ort +
          " , " +
          row.insuranceCompany[0].zipCode,
        header: "Adresse der Versicherung",
        size: 350,
      },
      {
        accessorFn: (row) => row.insuranceCompany[0].emailAddress,
        header: "Email der Versicherung",
        size: 350,
      },
      {
        accessorFn: (row) => row.claims[0].manufacturer[0].name,
        header: "Hersteller",
      },
      {
        accessorFn: (row) => row.claims[0].manufacturer[0].models[0].modalName,
        header: "Modell",
      },

      {
        accessorFn: (row) =>
          row.claims[0].manufacturer[0].models[0].modalPrice + " €",
        header: "Zeitwert",
      },
      {
        accessorFn: (row) => allDamagesString(row.claims),
        header: "Schaden",
      },
      {
        accessorFn: (row) => allClaimsPriceAddString(row.claims) + " €",
        header: "Reparaturpreis",
      },

      {
        accessorFn: (row) =>
          row.status === "pending" ? (
            <div className="flex px-5 gap-5 ">
              <Button
                onClick={() => {
                  handleUpdateStatus(row, "approved");
                }}
                variant="outlined"
                color="primary"
              >
                Auftrag annehmen
              </Button>
              <Button
                onClick={() => {
                  handleUpdateStatus(row, "declined");
                }}
                variant="outlined"
                color="error"
              >
                Decline
              </Button>
              <Button
                onClick={() => {
                  handleClickOpen(row);
                }}
                variant="outlined"
              >
                View Info
              </Button>
            </div>
          ) : row.status === "approved" ? (
            <>
              <Button
                onClick={() => {
                  handleUpdateStatus(row, "progress");
                }}
                variant="outlined"
                color="secondary"
              >
                Auftrag starten
              </Button>
              <Button
                onClick={() => {
                  handleClickOpen(row);
                }}
                variant="outlined"
              >
                View Info
              </Button>
            </>
          ) : row.status === "progress" ? (
            <>
              <div className="flex items-center gap-5">
                <Button
                  onClick={() => {
                    handleUpdateStatus(row, "completed");
                  }}
                  variant="outlined"
                  color="success"
                >
                  Auftrag abgeschlossen
                </Button>
                <Button
                  onClick={() => {
                    handleClickOpen(row);
                  }}
                  variant="outlined"
                >
                  View Info
                </Button>
              </div>
            </>
          ) : row.status === "completed" ? (
            <>
              <div className="flex items-center gap-5">
                <div>Job Done</div>
                <Button
                  onClick={() => {
                    handleClickOpen(row);
                  }}
                  variant="outlined"
                >
                  View Info
                </Button>
                <div
                  className="text-[#45afe3] cursor-pointer"
                  onClick={() => {
                    handleClickOpenJob(row._id);
                    handleUploadBillDetail(row);
                  }}
                >
                  <FileUploadIcon sx={{ color: "#45afe3" }} /> Upload Bill
                </div>
              </div>
            </>
          ) : row.status === "declined" ? (
            <div className="flex items-center gap-5">
              <div>Declined</div>
              <Button
                onClick={() => {
                  handleClickOpen(row);
                }}
                variant="outlined"
              >
                View Info
              </Button>
            </div>
          ) : (
            ""
          ),

        header: "Status",
        size: 450,
      },
    ],
    [imageName]
  );
  const columnsRequested = useMemo(() => [
    {
      accessorFn: (row) => row._id,
      header: "id",
    },
    {
      accessorFn: (row) =>
        row.status === "approved" ? (
          <Button
            onClick={() => {
              handleUpdateStatus(row, "progress", true);
            }}
            variant="outlined"
            color="secondary"
          >
            Auftrag starten
          </Button>
        ) : row.status === "progress" ? (
          <Button
            onClick={() => {
              handleUpdateStatus(row, "completed", true);
            }}
            variant="outlined"
            color="success"
          >
            Auftrag abgeschlossen
          </Button>
        ) : (
          <span>{row.status.toUpperCase()}</span>
        ),
      header: "Status",
    },
    {
      accessorFn: (row) => row.insuranceCompany[0].companyName,
      header: "Name der Versicherung",
      size: 300,
    },
    {
      accessorFn: (row) =>
        row.insuranceCompany[0].houseNumber +
        " , " +
        row.insuranceCompany[0].streetAddress +
        " , " +
        row.insuranceCompany[0].ort +
        " , " +
        row.insuranceCompany[0].zipCode,
      header: "Adresse der Versicherung",
      size: 350,
    },
    {
      accessorFn: (row) => row.insuranceCompany[0].emailAddress,
      header: "Email der Versicherung",
      size: 350,
    },
    {
      accessorFn: (row) => row.phoneManufacture,
      header: "Hersteller",
    },
    {
      accessorFn: (row) => row.phoneModal,
      header: "Modell",
    },
    {
      accessorFn: (row) => row.damages.map((el) => el + ", "),
      header: "Schaden",
    },
    {
      accessorFn: (row) =>
        row.price ? (
          row.price + " €"
        ) : (
          <Button
            onClick={() => {
              const price = prompt("Enter Price Here: ");
              handleUpdatePrice(row, price);
            }}
            variant="outlined"
            color="primary"
          >
            Give Quote
          </Button>
        ),
      header: "Reparaturpreis",
    },
  ]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseJobDialogue = () => {
    setOpenJobdialogue(false);
  };
  const customerInfo = [
    {
      label: "Kundenname",
      value: singleJob.customerName,
    },
    {
      label: "E-Mail-Addresse",
      value: singleJob.customerEmailAddress,
    },
    {
      label: "Telefonnummer",
      value: singleJob.customerPhone,
    },
    {
      label: "Adresse",
      value: singleJob.customerAddress,
    },
    {
      label: "imei",
      value: singleJob.customer_imei,
    },
  ];
  const insuranceCompanyInfo = [
    {
      label: "Name der Versicherung",
      value: singleJob.insuranceCompanyName,
    },
    {
      label: "E-Mail-Addresse",
      value: singleJob.IncEmailAddress,
    },
    {
      label: "Telefonnummer",
      value: singleJob.incPhone,
    },
    {
      label: "Adresse",
      value: singleJob.address,
    },
  ];
  const otherInfo = [
    {
      label: "Hersteller",
      value: singleJob.manufacturer,
    },
    {
      label: "Modell",
      value: singleJob.model,
    },
    {
      label: "Modellpreis",
      value: singleJob.modelPrice + " €",
    },
    {
      label: "Schaden",
      value: singleJob.damageParts,
    },
    {
      label: "Planpreis",
      value: singleJob.planPrice + " €",
    },
  ];

  return (
    <div>
      <Loader />
      <br />
      <br />
      <NavLink
        to="/dashboard"
        className="px-5 bg-black text-white w-20 text-center py-2 rounded"
      >
        zurück
      </NavLink>
      <br />
      <br />

      {/* upload bill dailogue  start */}
      <BootstrapDialog
        onClose={handleCloseJobDialogue}
        aria-labelledby="customized-dialog-title"
        open={openJobdialogue}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleCloseJobDialogue}
        >
          Upload Bill
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Kindly upload the bill in either JPG or PDF format.
          </Typography>
          <Typography gutterBottom>
            {!isImageUploaded ? (
              <div className="mt-10  flex items-center justify-center gap-10">
                {imageFile === "" ? (
                  <div>
                    <label
                      htmlFor="lessonThumbnail"
                      className=" px-3 py-2 rounded  cursor-pointer text-gray-500 "
                    >
                      <UploadIcon /> Upload Bill
                    </label>
                    <span className="text-sm text-gray-500">(.jpg format)</span>
                    <input
                      id="lessonThumbnail"
                      type="file"
                      onChange={(e) => {
                        setImageFile(e.target.files[0]);
                        setImageName(e.target.files[0].name);
                      }}
                      className="w-0 hidden"
                      accept="image/*"
                    />
                  </div>
                ) : (
                  <div className="text-gray-500 flex-col flex">
                    {imageName.substring(0, 20)}..
                    <span className="text-xs text-[#45afe3]">
                      ( Note: Click on upload button )
                    </span>
                  </div>
                )}

                <button
                  type="button"
                  onClick={handleImageUpload}
                  disabled={uploadBtn ? "disabled" : ""}
                  className={
                    imageFile
                      ? ` px-3 py-1 rounded  text-gray-500  cursor-pointer bg-slate-200 shadow-sm border-b`
                      : "hidden"
                  }
                >
                  {uploadBtn ? "Uploaded" : "Upload"}
                </button>
              </div>
            ) : (
              <span className="text-blue-500">
                Bill is already uploaded ....
              </span>
            )}
          </Typography>
        </DialogContent>
      </BootstrapDialog>
      {/* upload bill dailogue  end */}

      {/* dailogue  start */}
      <div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={handleClose}
          >
            Berufsinformation
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>
              {/* Presenting both the customer and the Insurance Company information
              for a particular claim in a clear and concise manner. */}
              Klare und prägnante Darstellung sowohl der Kunden- als auch der
              Versicherungsgesellschaftsinformationen für einen bestimmten
              Anspruch.
            </Typography>
            {/* <Typography
              gutterBottom
              sx={{
                textAlign: "right",
                margin: "10px 0px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Job Status : &nbsp;&nbsp;
              {singleJob.jobStatus.toUpperCase() === "COMPLETED"
                ? "Erledigt"
                : singleJob.jobStatus.toUpperCase() === "PENDING"
                ? "In Bearbeitung"
                : singleJob.jobStatus.toUpperCase() === "APPROVED"
                ? "Bestätigt"
                : singleJob.jobStatus.toUpperCase()}
            </Typography> */}
            <Typography
              gutterBottom
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                margin: "20px 0px",
              }}
            >
              Kundeninformation :
            </Typography>

            <Typography
              gutterBottom
              sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              {customerInfo.map((elem, index) => {
                return (
                  <Typography className="flex gap-5" key={index}>
                    <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                      {elem.label} :
                    </Typography>
                    <Typography gutterBottom>{elem.value}</Typography>
                  </Typography>
                );
              })}
            </Typography>
            <Typography
              gutterBottom
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                margin: "20px 0px",
              }}
            >
              Informationen zur Versicherung :
            </Typography>
            <Typography
              gutterBottom
              sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              {insuranceCompanyInfo.map((elem, index) => {
                return (
                  <Typography className="flex gap-5" key={index}>
                    <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                      {elem.label} :
                    </Typography>
                    <Typography gutterBottom>{elem.value}</Typography>
                  </Typography>
                );
              })}
            </Typography>
            <Typography
              gutterBottom
              sx={{
                fontWeight: "bold",
                fontSize: "20px",
                margin: "20px 0px",
              }}
            >
              Andere Information :
            </Typography>
            <Typography
              gutterBottom
              sx={{ display: "flex", flexDirection: "column", gap: "5px" }}
            >
              {otherInfo.map((elem, index) => {
                return (
                  <Typography className="flex gap-5" key={index}>
                    <Typography gutterBottom sx={{ fontWeight: "bold" }}>
                      {elem.label} :
                    </Typography>
                    <Typography gutterBottom>{elem.value}</Typography>
                  </Typography>
                );
              })}
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </div>
      {/* dailogue   end */}
      {/* <!--Tabs navigation for primary color--> */}
      <ul
        className="mb-5 flex list-none flex-row flex-wrap border-b-0 pl-0"
        role="tablist"
        data-te-nav-ref
      >
        <li role="presentation">
          <a
            href="#tabs-home0"
            className="my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-bold leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
            data-te-toggle="pill"
            data-te-target="#tabs-home0"
            data-te-nav-active
            role="tab"
            aria-controls="tabs-home0"
            aria-selected="true"
          >
            Aufträge
          </a>
        </li>
        <li role="presentation">
          <a
            href="#tabs-profile0"
            className="focus:border-transparen my-2 block border-x-0 border-b-2 border-t-0 border-transparent px-7 pb-3.5 pt-4 text-xs font-bold leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary dark:text-neutral-400 dark:hover:bg-transparent dark:data-[te-nav-active]:border-primary-400 dark:data-[te-nav-active]:text-primary-400"
            data-te-toggle="pill"
            data-te-target="#tabs-profile0"
            role="tab"
            aria-controls="tabs-profile0"
            aria-selected="false"
          >
            Angefragt
          </a>
        </li>
      </ul>

      {/* <!--Tabs content for primary color--> */}
      <div className="mb-6">
        <div
          className="hidden opacity-100 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
          id="tabs-home0"
          role="tabpanel"
          aria-labelledby="tabs-home-tab0"
          data-te-tab-active
        >
          <div className="my-10">
            <MaterialReactTable
              columns={columns}
              data={jobList}
              initialState={{
                columnVisibility: { id: false, hideStatus: false },
              }}
              enableHiding={false}
              enableColumnFilters={false}
            />
          </div>
        </div>
        <div
          className="hidden opacity-0 transition-opacity duration-150 ease-linear data-[te-tab-active]:block"
          id="tabs-profile0"
          role="tabpanel"
          aria-labelledby="tabs-profile-tab0"
        >
          <div className="my-10">
            <MaterialReactTable
              columns={columnsRequested}
              data={requestedJobList}
              initialState={{
                columnVisibility: { id: false },
              }}
              enableHiding={false}
              enableColumnFilters={false}
            />
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};
export default Jobs;
