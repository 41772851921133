import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import MyAccount from "./pages/MyAccount";
import Myjobs from "./pages/Myjobs";
import ProtectedRoutes from "./ProtectedRoutes";

import { useEffect } from "react";
import { Dropdown, Ripple, initTE } from "tw-elements";

const App = () => {

  useEffect(() => {
    initTE({ Dropdown, Ripple });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<ProtectedRoutes Component={Login} />} />
        <Route
          path="/myjobs"
          element={<ProtectedRoutes Component={Myjobs} />}
        />
        <Route
          path="/myaccount"
          element={<ProtectedRoutes Component={MyAccount} />}
        />
      </Routes>
    </BrowserRouter>
  );
};
export default App;
