const Footer = () => {
  return (
    <div className="flex justify-center gap-3 my-5 text-gray-500">
      <div>Kontakt</div>
      <div>Impressum</div>
      <div>Datenschutz</div>
    </div>
  );
};

export default Footer;
