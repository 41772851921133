import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { serverHost } from "../config";
import { useStateContext } from "../contexts/ContextProvider";
const UserNavbar = () => {
  const {
    dynamicUserName,
    setDynamicUserName,
    setDynamicUserId,
    setDynamicUserData,
    dynamicUserData,
  } = useStateContext();
  useEffect(() => {
    const authToken = localStorage.getItem("auth-token");
    fetch(`${serverHost}/api/ppws/getuser`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": authToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log({ res });
        setDynamicUserData(res.repairShop);
        setDynamicUserName(res.repairShop.emailAddress.split("@")[0]);
        setDynamicUserId(res.repairShop._id);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, [setDynamicUserName, setDynamicUserId, setDynamicUserData]);

  return (
    <>
      <div className="h-28 border-b border-gray-300 flex items-center justify-center lg:justify-between lg:flex-row flex-col gap-3">
        <div className="uppercase text-2xl sm:text-4xl text-[#45afe3] font-extrabold">
          Priceparent.de
        </div>
        <div className="flex items-center justify-center gap-3 sm:gap-10 md:gap-20 lg:gap-5 text-gray-500 text-sm">
          <div>
            Sie sind angemeldet als:
            <b> {dynamicUserName}</b>
          </div>
          {/* 
          <div className="flex justify-center">
            <div>
              <div className="relative" data-te-dropdown-ref>
                <button
                  className="flex items-center whitespace-nowrap rounded px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white transition duration-150 ease-in-out motion-reduce:transition-none  text-center"
                  type="button"
                  id="dropdownMenuButton1"
                  data-te-dropdown-toggle-ref
                  aria-expanded="false"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                >
                  <img
                    src={
                      dynamicUserData.imageUrl
                        ? dynamicUserData.imageUrl
                        : "https://cdn-icons-png.flaticon.com/512/61/61205.png"
                    }
                    alt=""
                    className="img-fluid w-12 h-12 rounded-full"
                  />
                </button>
                <ul
                  className="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg  [&[data-te-dropdown-show]]:block"
                  aria-labelledby="dropdownMenuButton1"
                  data-te-dropdown-menu-ref
                >
                  <li>
                    <NavLink
                      className="block w-full whitespace-nowrap bg-white py-2 px-4 text-sm font-normal text-gray-500 hover:bg-gray-100"
                      to="/myaccount"
                      data-te-dropdown-item-ref
                    >
                      Mein Konto
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="block w-full whitespace-nowrap bg-white py-2 px-4 text-sm font-normal text-gray-500 hover:bg-gray-100"
                      to="/myjobs"
                      data-te-dropdown-item-ref
                    >
                      Meine Aufträge
                    </NavLink>
                  </li>
                  <li>
                    <div
                      className="block w-full whitespace-nowrap bg-white py-2 px-4 text-sm font-normal text-red-500 hover:bg-gray-100 cursor-pointer"
                      onClick={() => {
                        localStorage.removeItem("auth-token");
                        window.location = "/";
                      }}
                      data-te-dropdown-item-ref
                    >
                      Logout
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}

          <div class="dropdown">
            <span>
              <img
                src={
                  dynamicUserData.imageUrl
                    ? dynamicUserData.imageUrl
                    : "https://cdn-icons-png.flaticon.com/512/61/61205.png"
                }
                alt=""
                className="img-fluid w-12 h-12   rounded-full"
              />
            </span>
            <div class="dropdown-content">
              <p className="my-3  py-2">
                <NavLink
                  to="/myaccount"
                  className="w-full py-2 px-3 hover:bg-slate-200 cursor-pointer rounded"
                >
                  Mein Konto
                </NavLink>
              </p>

              <p className="my-3  py-2">
                <NavLink
                  to="/myjobs"
                  className="py-2 px-3 hover:bg-slate-200 cursor-pointer rounded"
                >
                  Meine Aufträge
                </NavLink>
              </p>
              <p className="my-3  py-2">
                <p
                  className="py-2 px-3 hover:bg-red-200 cursor-pointer rounded text-red-500 "
                  onClick={() => {
                    localStorage.removeItem("auth-token");
                    window.location = "/";
                  }}
                >
                  Logout
                </p>
              </p>
            </div>
          </div>
          {/* <div
            onClick={() => {
              localStorage.removeItem("auth-token");
              window.location = "/";
            }}
            className="text-red-500 font-bold  flex items-center"
          >
            <div className="block md:hidden">
              <Tooltip placement="top" title="Logout">
                <IconButton>
                  <LogoutIcon sx={{ color: "#ef4444" }} />
                </IconButton>
              </Tooltip>
            </div>
            <span className="hidden md:block">Logout</span>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default UserNavbar;
