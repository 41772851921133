import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";

const Home = () => {
  return (
    <div className="lg:mx-40 md:mx-20 mx-5">
      <Navbar />
      <Header />
      <Footer />
    </div>
  );
};

export default Home;
