import React from "react";
import Jobs from "../components/Jobs";
import UserNavbar from "../components/UserNavbar";

const Myjobs = () => {
  return (
    <div className="lg:mx-40 md:mx-20 mx-5">
      <UserNavbar />
      <Jobs />
    </div>
  );
};

export default Myjobs;
