import { NavLink } from "react-router-dom";
// import logo from "../assets/images/logo.png";
import logo from "../assets/images/priceparent_word.png";

const Header = () => {
  return (
    <>
      <div className="main-header-image py-5 text-white my-5 bg-[#45afe3] rounded-lg flex flex-col items-center  h-[700px] justify-evenly gap-5">
        <img src={logo} alt="im" className="sm:w-96 w-60" />

        <div className="text-center text-white  px-4 text-lg font-bold">
          Das Portal für einen besseren Service und mehr Preistransparenz
        </div>
        <NavLink
          to="/login"
          className="border border-white px-3 py-2 rounded-lg font-bold cursor-pointer"
        >
          Zur Anmeldung
        </NavLink>
        {/* <div className="flex items-center justify-between md:flex-row flex-col gap-3 text-lg font-bold">
          <div className="cursor-pointer">Noch kein Zugang?</div>
          <div className="cursor-pointer">Passwort vergessen?</div>
          <div className="cursor-pointer ">Aktivierungs-Emails fehit?</div>
        </div> */}
      </div>
    </>
  );
};

export default Header;
